<template>
  <div class="study-material">
    <LoadingSpinner :is-loaded="isLoaded">
      <AdvancedMarkdown :source="material.content" class="my-4" />
    </LoadingSpinner>
  </div>
</template>

<script>
import {
  apiStudyMaterials,
  AdvancedMarkdown,
  LoadingSpinner,
} from "frontend-common";

export default {
  name: "StudyMaterial",
  mixins: [apiStudyMaterials],
  components: {
    AdvancedMarkdown,
    LoadingSpinner,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      material: {},
      isLoaded: false,
    };
  },
  computed: {},
  watch: {
    $route() {
      this.loadPage();
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    loadPage() {
      this.apiStudyMaterial(this.slug)
        .then((response) => {
          this.material = response;
        })
        .finally(() => (this.isLoaded = true));
    },
  },
};
</script>

<style scoped>
.study-material {
  padding-inline: 1em;
  padding-block: 1em;
}
</style>
